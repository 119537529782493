import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import ConfirmPrompt from "commonComponents/confirmPrompt";
import globalStyles from "Styles/globalStyles";
import { isEmpty, isNull } from "lodash";
import { getAllFilters } from "actions/filterAction";
import { Button, Grid } from "@mui/material";
import Form from "Utils/form";
import { addSnack } from "../../../../actions/snackbarActions";
import Loader from "Utils/Loader/loader";
import { TENANT } from "config/api";

const common = {
  __Multiple_Value: "(s)",
  __Assortment: "AssortSmart",
  __ConfirmBtnText: "Yes",
  __RejectBtnText: "No",
  __LaterBtnText: "Later",
  __Select_Drops: "Select # of drops",
  __Drop: "Drop",
  __Attribute_Text: "Attribute",
  __Month_Text: "Month",
  __Month_mapping_list: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  __compare_yr_constants: ["LY", "LLY", "LLLY"],
  __seed_with_constants: ["IAF", "LY", "LLY"],
  __number_of_drops: 2,
  __default_column_attributes: {
    is_aggregated: false,
    is_editable: false,
    is_frozen: false,
    is_hidden: false,
    is_required: false,
    is_row_span: false,
    is_searchable: false,
  },
  __create_plan_selling_period_enabled_start_days: ["Sunday"],
  __create_plan_selling_period_enabled_end_days: ["Saturday"],
  __create_plan_seasons: ["Summer", "Fall", "Spring", "Winter"],
  __sub_channel_wholesale: ["Amazon", "Key_Account", "Specialty"],
  __MojoHelpdesk_Link:
    "https://impactanalytics.mojohelpdesk.com/login/create_request#/ticket-form/66734",
  __Finalize_Steps: ["2.4", "3"],
  __Plan_stage: { "Working Plan": 0, "Scenario Plan": 2 },
};

const useStyles = makeStyles(() => ({
  form: {
    alignItems: "flex-end",
    display: "flex",
    marginBottom: "3rem",
  },
  tableAlignment: {
    textAlign: "center",
    margin: "auto",
    width: "80%",
  },
}));

const AddSourceMappingConfig = (props) => {
  // using common and individual styles of the component
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [newRowFormData, setNewRowFormData] = useState({});
  const [addRowTableData, setAddRowTableData] = useState([]);
  const [newRowFormFields, setNewRowFormFields] = useState([]);
  const [loadAddNewRowColumns, setLoadAddNewRowColumns] = useState(false);
  const [closeAddRowModalConfirm, setCloseAddRowModalConfirm] = useState(
    false
  );
  const [deleteAddUserModalConfirm, setDeleteAddUserModalConfirm] = useState(
    false
  );
  const [deleteUserIndex, setDeleteUserIndex] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(()=>{
    if(props.loaderMappingTable)
      setLoader(true)
    else
      setLoader(false)
  },[props.loaderMappingTable])

  useEffect(async () => {
    setLoader(true)
    setLoadAddNewRowColumns(true);
    // const formFieldsData = await props.getAllFilters("uam screen");
    if (true ){
      const newFormFields = props.newFormFields.map((data) => {
        return {
          ...data,
          required: data.is_required,
          error: false,
          accessor: data.column_name,
          field_type: data.display_type,
          value_type: data.column_name === "email" ? "email" : "text",
          isDisabled: props.editFormData ? ["table","view","tenant"].includes(data.column_name) : false
        };
      });
      setNewRowFormFields(newFormFields);
      setLoader(false)
      if(props.editFormData){
        setNewRowFormData({...props.editFormData})
      }else{
        const newRowData = props.newFormFields.filter((data)=>{
          if(data.display_type === "ToogleField")
            return {[data.accessor]: false}
          
        })
        let newObj = newRowData.map((data)=> {
          return {[data.column_name]: false}})
        setNewRowFormData(Object.assign({}, ...newObj ))
      }
      
    }
    
  }, []);

  const deleteUserRecord = (index) => {
    if (!isNull(index)) {
      let addUserTableDataCopy = [...addRowTableData];
      addUserTableDataCopy.splice(index, 1);
      setAddRowTableData([...addUserTableDataCopy]);
    }
    setDeleteUserIndex(null);
  };

  const closeAddNewUserModal = () => {
    props.handleClose();
  };

  const handleUserDetailsOnChange = (updateFormValue) => {
    if(newRowFormData?.replace !== updateFormValue?.replace){
      let updatedFormFields = newRowFormFields.map((field)=>{
        if(field.accessor === "partition_column" || field.accessor === "clustering_columns"){
          return {
            ...field,
            is_mandatory : updateFormValue?.replace,
            is_required : updateFormValue?.replace,
            required: updateFormValue?.replace
          }
        }
        return {...field}
      })
      setNewRowFormFields(updatedFormFields)
    }
    setNewRowFormData(updateFormValue);
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const saveNewUsersCreated = () => {
    if (addRowTableData.length !== 0) {
      let userTableDetails = addRowTableData.map((user) => {
        return {
          email: user.email.toLowerCase(),
          user_name: user.user_name[0].toUpperCase() + user.user_name.slice(1),
          custom_attributes: {
            retail_pro_id: user.retail_pro_id,
          },
        };
      });
      props.onApply(userTableDetails[0]);
    } else {
      displaySnackMessages("Cannot submit empty data", "error");
    }
  };

  const formItemValueAfterValidation = (item, value) => {
    return {
      ...item,
      error: value,
    };
  };

  const validateFormFields = () => {
    let formFieldsList = newRowFormFields.map((item) => {
      // if (item.accessor === "user_name") {
      //   let regexp = /^[a-zA-Z ]+$/;
      //   if (!regexp.test(newRowFormData["user_name"])) {
      //     return formItemValueAfterValidation(item, true);
      //   } else {
      //     return formItemValueAfterValidation(item, false);
      //   }
      // } else {
      //   let result = emailIdValidation(newRowFormData["email"]);
      //   if (!result) {
      //     return formItemValueAfterValidation(item, true);
      //   } else {
      //     return formItemValueAfterValidation(item, false);
      //   }
      // }
      return formItemValueAfterValidation(item, false);
    });
    setNewRowFormFields(formFieldsList);
    return formFieldsList.some((obj) => obj.error);
  };

  const addNewUserDetailsToTable = () => {
    let requiredFieldsError = false;
    let duplicates = false;
    let formFieldCheck = false;
    //  check for empty values
    [...newRowFormFields].forEach((item) => {
      // remove whitespaces or avoid white space entries
      if(newRowFormData[item.display_type!== "BooleanField"])
        newRowFormData[item.accessor] = newRowFormData[item.accessor]?.trim();
      if (item.required)
          if(newRowFormData[item.accessor] === false ? newRowFormData[item.accessor] : !newRowFormData[item.accessor]) {
            requiredFieldsError = true;
      }
    });

    if (requiredFieldsError) {
      displaySnackMessages(
        "Please Enter the data in all the required fields",
        "error"
      );
    } else {
      formFieldCheck = validateFormFields();
    }
    // To check duplicate email id entries
    // addRowTableData.forEach((row) => {
    //   if (row.email === newRowFormData.email) {
    //     duplicates = true;
    //     displaySnackMessages("Entered email id already exists", "warning");
    //   }
    // });

    if (!requiredFieldsError && !formFieldCheck) {
      let formData = {...newRowFormData}
      if(newRowFormData?.use_standard_csv_parser){
        formData.miscellaneous_attributes = JSON.stringify({"use_standard_csv_parser" : newRowFormData?.use_standard_csv_parser})
        delete formData?.use_standard_csv_parser
      }
      let tableData = [...addRowTableData];
      tableData = [...tableData, formData];
      setAddRowTableData(tableData);
      if(props.editFormData){
        let filterPayload = {}
        for (const key in formData) {
          if (props.editFormData[key] !== formData[key]) {
            filterPayload[key] = formData[key]      
          }
        }
        if(!isEmpty(filterPayload)){
        props.updateMappingTableData({
          table:formData.table,
          view: formData.view,
          ...filterPayload})
        }else{
          displaySnackMessages("No data to update", "warning");
        }
      }else
        props.onApply({tenant: TENANT , ...formData})
      // setNewRowFormData({});
    }
  };

  /**
   *
   * @param {table params object} params
   * Summary: This function is called on click of delete button on any row in the add users table
   *          In this function, we set the deleted row index to state variable and enable the
   *          delete confirm prompt to let the user confirm the operation. We get row index in the params
   *          object
   */
  const onUserDelete = (params) => {
    setDeleteUserIndex(params.rowIndex); //set the state variable to the row index
    setDeleteAddUserModalConfirm(true); //enable the delete prompt
  };

  const handleButtonClick = (data) => {
    if (
      data?.view &&
      newRowFormData?.connector &&
      newRowFormData?.source_config &&
      newRowFormData?.table
    ) {
      props.setselectedView(data);
      props.setViewModal(true);
    } else {
      displaySnackMessages(
        "Please Enter the data in Connector, Source Config, View and Table fields to view sample data",
        "warning"
      );
    }
  };

  return (
    <>
      <ConfirmPrompt
        showModal={true}
        title={props.configurationTab ? (props.editFormData ? "Edit Row" : "Add Row") : "Add Trigger"}
        message=""
        ariaLabeledBy="addUser-dialog"
        primaryBtnText="Save"
        secondaryBtnText="Cancel"
        showCloseIcon={true}
        size="lg"
        hideActionFooter={true}
        confirmCallback={(val) => {
          if (!val) {
            closeAddNewUserModal();
          }
        }}
      >
        <Loader loader={loader}>
        <div className={globalClasses.marginBottom}>
          <div className={classes.form}>
            <Form
              layout={"vertical"}
              maxFieldsInRow={3}
              handleChange={handleUserDetailsOnChange}
              fields={newRowFormFields}
              updateDefaultValue={false}
              defaultValues={newRowFormData}
              handleDropdownClose={true}
              handleButtonClick={handleButtonClick}
            ></Form>
            {/* <div className={globalClasses.marginLeft1rem}>
              <Button
                variant="contained"
                onClick={addNewUserDetailsToTable}
                id="createProductApplyBtn"
                color="primary"
                disabled={loadAddNewRowColumns}
              >
                Add
              </Button>
            </div> */}
          </div>
          {/* <Loader loader={loadAddNewRowColumns}>
            <div className={classes.tableAlignment}>
              <AgGrid
                columns={addUserTableColumns}
                rowdata={[]}
                sizeColumnsToFitFlag
                callDeleteApi={onUserDelete}
                uniqueRowId={"email"}
              />
            </div>
          </Loader> */}
        </div>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          className={globalClasses.gap}
        >
          <Button
            variant="contained"
            color="primary"
            title="Save"
            disabled={props.loaderUserMgm}
            onClick={(val) => {
              if (val) {
                // saveNewUsersCreated();
                addNewUserDetailsToTable()
              } else {
                addRowTableData.length > 0
                  ? setCloseAddRowModalConfirm(true)
                  : closeAddNewUserModal();
              }
            }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="primary"
            title="Cancel"
            onClick={setCloseAddRowModalConfirm}
            disabled={props.loaderUserMgmt}
          >
            Cancel
          </Button>
        </Grid>
        </Loader>
      </ConfirmPrompt>
      <ConfirmPrompt
        showModal={closeAddRowModalConfirm}
        title="Close Add Row"
        message="Your changes will be discarded if you proceed. Are you sure you want to close?"
        ariaLabeledBy="close-addUser-confirmation"
        primaryBtnText={common.__ConfirmBtnText}
        secondaryBtnText={common.__RejectBtnText}
        showCloseIcon={true}
        setConfirm={setCloseAddRowModalConfirm}
        confirmCallback={(val) => {
          if (val) {
            closeAddNewUserModal();
          }
        }}
      />
      <ConfirmPrompt
        showModal={deleteAddUserModalConfirm}
        title="Delete Added config"
        message="Are you sure you want to delete config "
        ariaLabeledBy="delete-addUser-confirmation"
        primaryBtnText={common.__ConfirmBtnText}
        secondaryBtnText={common.__RejectBtnText}
        showCloseIcon={true}
        setConfirm={setDeleteAddUserModalConfirm}
        confirmCallback={(val) => {
          if (val) {
            deleteUserRecord(deleteUserIndex);
          }
        }}
      />
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    loaderUserMgmt:
      store.tenantUserRoleMgmtReducer.userManagementReducer.loaderUserMgmt,
  };
};

const mapActionsToProps = {
  getAllFilters,
  addSnack
};

export default connect(mapStateToProps, mapActionsToProps)(AddSourceMappingConfig);
