import axios from "axios";
import { config } from "../../config";
import { logoutUser } from "../../actions/authActions";
import store from "../../store";
import {
  screenNamesNotIncludedInFilterExclusion,
  screenUrlsIncludedInFilterExclusion,
} from "config/constants";
import { getToken } from "Utils/functions/helpers/authentication-helpers";
import {
  getCurrentApplicationDetails,
  getTenantTimeZoneDetails,
} from "commonComponents/coreComponentScreen/utils";
const axiosPlatformInstance = axios.create({ baseURL:  `https://${window.location.host}/api/v2` });

const requestHandler = async (request) => {
  // for few apis baseURL will be changed to api/v3, pass isV3 key from service file
  const { applicationCode = "" } = getCurrentApplicationDetails();
  const {
    tenantDateFormat = "",
    tenantTimeZone = "",
  } = getTenantTimeZoneDetails();
  if (request.isV3) {
    request.baseURL = config.baseUrlV3;
  }

  const token = await getToken();
  request.headers.common = {
    Authorization: `${token}`,
    "application-code": applicationCode,
    time_format: tenantDateFormat,
    time_zone: tenantTimeZone
  };
  let excludedFilterValues = store.getState().tenantUserRoleMgmtReducer
    .userRoleManagementReducer.filter_attribute_exclusion_values;
  let l_allAPIsIncludedInFilterExclusion = store.getState()
    .tenantUserRoleMgmtReducer.userRoleManagementReducer
    .allAPIsIncludedInFilterExclusion;
  // let excludedFilterValuesForScreen = store.getState().assortsmartReducer
  //   .commonAssortReducer.activeScreenName;

  //Find the index of the url in the constants array. Here we are doing substring match
  //to support query params urls as well
  let excludedURLIndex = screenUrlsIncludedInFilterExclusion.findIndex(
    (urlObject) => request.url.includes(urlObject.url)
  );
  if (
    request.data &&
    request.data?.filters &&
    excludedFilterValues?.length > 0 &&
    excludedURLIndex > -1
  ) {
    const excludedURLObject =
      screenUrlsIncludedInFilterExclusion[excludedURLIndex];
    //If there is no dimensions key added to the url object,
    //It is assumed that the api supports all dimensions and excluded values
    //stored in the db are passed directly without filtering by dimension
    if (!excludedURLObject?.dimensions) {
      request.data.filters = [...request.data.filters, ...excludedFilterValues];
    } else {
      //If dimensions array is present for an url object,
      //we loop over all the dimensions present in the array
      //Each dimension is an object with below key - value pairs
      // 1. dimension - dimension string ["product", "store", "vendor"..]
      // 2. key - value in the payload - ex: "filters", "product_attributes" etc..
      for (const dimensionObject of excludedURLObject.dimensions) {
        const dimensionKey = dimensionObject?.key || "filters"; //Get the dimension key
        const dimension = dimensionObject?.dimension; //Get the dimension
        request.data[dimensionKey] = [
          ...request.data[dimensionKey],
          ...excludedFilterValues.filter(
            (filter) => filter.dimension === dimension
          ), //filter the values based on the dimenison and append the payload based on the above key
        ];
      }
    }
  }
  if (request?.config?.onUploadProgress) {
      const originalUploadProgress = request.config.onUploadProgress;
  
      request.config.onUploadProgress = (progressEvent) => {
        // const percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        originalUploadProgress(progressEvent);
      };
    }
  return request;
};

const errorHandler = async (error) => {
  if (error.response && error.response.status === 401) {
    store.dispatch(logoutUser());
  }
  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  return response;
};
axiosPlatformInstance.interceptors.request.use((request) => requestHandler(request));
axiosPlatformInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default axiosPlatformInstance;
