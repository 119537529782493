import { createSelector, createSlice } from "@reduxjs/toolkit";

import axiosInstance from "Utils/axios";
import {
  GET_ROLE_MGMT_FILTER_CONFIG,
  TABLE_HIERARCHY_ASSIGN_ROLE,
  FETCH_TABLE_BASED_ON_FILTER,
  FETCH_UNASSIGNED_ROLES_LIST,
  SAVE_UPDATE_DELETE_USER_ROLE_MAPPING,
  GET_ROLE_MGMT_TABLE_CONFIG,
  ASSIGN_USER_ROLE_FORM_FILTERS,
  GET_URM_FILTERS_LIST,
} from "config/api";
import { cloneDeep, isNil } from "lodash";

export const userRoleManagementService = createSlice({
  name: "userRoleManagementService",
  initialState: {
    assignUserRoleMgmtLoader: false,
    isSuperUser: false,
    planningLevelHierarchy: [],
    userLevelHierarchy: [],
    userAccessList: { create: {}, delete: {}, edit: {}, approve: {} },
    tenantDateFormat: "MM-DD-YYYY",
    tenantTimeZone: "",
    filter_attribute_exclusion_values: [],
    allAPIsIncludedInFilterExclusion: false,
    productDimensionHierarchy: [
      "product_channel_name",
      "l0_name",
      "l1_name",
      "l2_name",
    ],
    storeDimensionHierarchy: ["channel"],
    selectionAutoPopulate: {
      product: {
        article: ["l0_name", "l1_name", "l2_name"],
        product_description: ["l0_name", "l1_name", "l2_name"],
        style_description: ["l0_name", "l1_name", "l2_name"],
      },
      store: { group_id: ["channel"] },
    },
    currentScreenName: "",
    ticketingConfig: {
      isEnabled: true,
    },
    isEditMode: false,
    filterConfigType: "global",
    filterSaveLevel: "mandatory",
    editUserRoleMappingData: {},
    newUsersList: [],
    tenantUamConfig: { table_uam: false, filter_uam: true },
    client_specific_super_users: [],
  },
  reducers: {
    setTableHierarchyOnAssignRole: (state, action) => { },
    setIsSuperUser: (state, action) => {
      state.isSuperUser = action.payload;
    },
    setPlanningLevelHierarchy: (state, action) => {
      state.planningLevelHierarchy = action.payload;
    },
    setUserLevelHierarchy: (state, action) => {
      state.userLevelHierarchy = action.payload;
    },
    setUserAccessList: (state, action) => {
      state.userAccessList = action.payload;
    },
    setfilterAttributeExclusionValues: (state, action) => {
      state.filter_attribute_exclusion_values = action.payload;
    },
    setAllAPIsIncludedInFilterExclusion: (state, action) => {
      state.allAPIsIncludedInFilterExclusion = action.payload;
    },
    setTenantTimeconfig: (state, action) => {
      state.tenantTimeZone =
        action.payload?.[0]?.attribute_value?.value?.time_zone || "";
      state.tenantDateFormat =
        action.payload?.[0]?.attribute_value?.value?.time_format ||
        "MM-DD-YYYY";
    },
    setCurrentScreenName: (state, action) => {
      state.currentScreenName = action?.payload || "";
    },
    setTicketingModuleConfig: (state, action) => {
      state.ticketingConfig = { ...action.payload };
    },
    setEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    setEditUserRoleMappingData: (state, action) => {
      state.editUserRoleMappingData = { ...action.payload };
    },
    setNewUsersList: (state, action) => {
      state.newUsersList = [...action.payload];
    },
    setFilterMappingeConfig: (state, action) => {
      if (!action.payload) {
        state.filterConfigType = "global";
        state.filterSaveLevel = "mandatory";
      } else {
        state.filterConfigType = action.payload.config_type
          ? action.payload.config_type
          : "global";
        state.filterSaveLevel = action.payload.save_level
          ? action.payload.save_level
          : "mandatory";
      }
    },
    setTenantUamconfig: (state, action) => {
      const uamConfig = action.payload?.[0]?.attribute_value;
      if (!isNil(uamConfig?.table_uam) && !isNil(uamConfig?.filter_uam)) {
        state.tenantUamConfig.table_uam = uamConfig.table_uam;
        state.tenantUamConfig.filter_uam = uamConfig.filter_uam;
      }
    },
    setClientSpecificSuperUsers: (state, action) => {
      state.client_specific_super_users = cloneDeep(action?.payload || []);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserRoleMgmtLoader,
  setTableHierarchyOnAssignRole,
  setIsSuperUser,
  setPlanningLevelHierarchy,
  setUserLevelHierarchy,
  setUserAccessList,
  setTenantTimeconfig,
  setfilterAttributeExclusionValues,
  setAllAPIsIncludedInFilterExclusion,
  setCurrentScreenName,
  setTicketingModuleConfig,
  setEditMode,
  setNewUsersList,
  setEditUserRoleMappingData,
  setFilterMappingeConfig,
  setTenantUamconfig,
  setClientSpecificSuperUsers,
} = userRoleManagementService.actions;

export const getFilterConfiguration = () => () => {
  return axiosInstance({
    url: GET_ROLE_MGMT_FILTER_CONFIG,
    method: "GET",
    data: "",
  });
};

export const getTableConfig = () => async () => {
  return axiosInstance({
    url: GET_ROLE_MGMT_TABLE_CONFIG,
    method: "GET",
  });
};

export const getTableHierarchyOnAssignRole = (postBody) => () => {
  return axiosInstance({
    url: TABLE_HIERARCHY_ASSIGN_ROLE,
    method: "POST",
    data: postBody,
  });
};

export const saveUserRoleMappings = (postBody) => async () => {
  return axiosInstance({
    url: `${SAVE_UPDATE_DELETE_USER_ROLE_MAPPING}`,
    method: "POST",
    data: postBody,
  });
};

export const updateUserRoleMappings = (postBody) => async () => {
  return axiosInstance({
    url: `${SAVE_UPDATE_DELETE_USER_ROLE_MAPPING}`,
    method: "PUT",
    data: postBody,
  });
};

export const getRolesMappedToUserData = (postBody) => async () => {
  return axiosInstance({
    url: `${FETCH_TABLE_BASED_ON_FILTER}`,
    method: "POST",
    data: postBody,
    headers: {
      "application-code": "6",
    }
  });
};

export const getUnmappedUserRoles = () => async () => {
  return axiosInstance({
    url: `${FETCH_UNASSIGNED_ROLES_LIST}`,
    method: "GET",
    headers: {
      "application-code": "6",
    },
  });
};

export const deleteRolesAssigned = (id) => async () => {
  return axiosInstance({
    url: `${SAVE_UPDATE_DELETE_USER_ROLE_MAPPING}/${id}`,
    method: "DELETE",
  });
};

export const getUserDetailsFormFilter = (postBody) => async () => {
  return axiosInstance({
    url: `${ASSIGN_USER_ROLE_FORM_FILTERS}`,
    method: "POST",
    data: postBody,
  });
};

export const getUrmFilters = (postBody) => async () => {
  const { data } = await axiosInstance({
    url: `${GET_URM_FILTERS_LIST}`,
    method: "POST",
    data: postBody,
  });

  return data.data;
};

export const userRoleManagementServiceSelector = createSelector(
  (state) => state,
  (state) => state.tenantUserRoleMgmtReducer.userRoleManagementReducer
);

export const userAccessListSelector = createSelector(
  userRoleManagementServiceSelector,
  (state) => state.userAccessList
);

export default userRoleManagementService.reducer;
